.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.about-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.about-hero-heading {
  max-width: 800px;
  text-align: center;
}
.about-creds {
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  background-color: var(--dl-color-custom-accent1);
}
.about-container2 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  
}
.about-credentials {
  display: flex;
  align-items: flex-start;
  margin-right: 0px;
  flex-direction: column;
  align-items: center;
}
.about-text {
  font-size: 1.75rem;
  margin-bottom: var(--dl-space-space-unit);
}
.about-container3, 
.about-container4, 
.about-container5, 
.about-container6 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
}
.about-icon {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  align-self: center;
}
.about-text03, 
.about-text04, 
.about-text05, 
.about-text06 {
  color: rgb(255, 255, 255);
  font-size: 1.05rem;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}
.about-icon03,
.about-icon04,
.about-icon05,
.about-icon06 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  align-self: center;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.about-container7 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.about-icon07 {
  fill: var(--dl-color-custom-neutral-dark);
  width: var(--dl-size-size-xlarge);
  height: auto;
  visibility: hidden;
}
.about-container9 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.about-text09 {
  color: rgb(255, 255, 255);
  font-size: 1.05rem;
  align-self: center;
  text-align: center;
}
@media(max-width: 991px) {
  .about-hero1 {
    flex-direction: column;
  }
  .about-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .about-hero-heading {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .about-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-creds {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-credentials {
    margin-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .about-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-creds {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-container2 {
    flex-direction: column;
  }
  .about-credentials {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text08 {
    text-align: left;
  }
  .about-text09 {
    text-align: left;
    text-align: justify;
  }
  .about-text03, 
  .about-text04, 
  .about-text05, 
  .about-text06 {
    color: rgb(255, 255, 255);
    font-size: 1rem;
    align-self: center;
    margin-left: var(--dl-space-space-halfunit);
  }
}
