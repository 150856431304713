.request-span {
  font-size: small;
  color: var(--dl-color-custom-neutral-light);
}
label {
  color: var(--dl-color-custom-neutral-light);
  font-size: small;
}
.request-input input, .request-input label {
  margin-top: 1em;
}
.request-file-upload input {
  padding: 0%;
  color: var(--dl-color-custom-neutral-light);
  height: var(--dl-space-space-twounits);
  display: flex;
  flex-direction: column;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(4, 21, 37, 0.9);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0; 
  left: 0; 
  z-index: 1;
}
.requestForm {
  max-width: 300px;
  border: 1px solid white;
}
.requestInput {
  display: flex;
  flex-direction: column;
  min-width: 333px;
  max-width: 333px;
}
.requestInput select {
  border-radius: var(--dl-radius-radius-radius4);
  height: var(--dl-space-space-twounits);
  border: 0px;
}
.requestInput input {
  border-radius: var(--dl-radius-radius-radius4);
  border: 0px;
  height: var(--dl-space-space-twounits);
}
.requestInput input, .requestInput label {
  margin-top: 1em;
}
.closeButton {
  all: unset;
  position: absolute;
  top: 15%;
  right: 15%;
  color: var(--dl-color-custom-neutral-light);
  color: var(--dl-color-custom-neutral-light);
  background-color: rgb(0, 119, 229);
  border-radius: var(--dl-radius-radius-radius4);
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  border: 0px;
  margin-top: 1em;
  cursor: pointer;
}
.request-file-upload input::file-selector-button:hover {
  cursor: pointer;
}

.request-file-upload input::file-selector-button {
  color: var(--dl-color-custom-neutral-light);
  background-color: rgb(0, 119, 229);
  border-radius: var(--dl-radius-radius-radius4);
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  border: 0px;
}
.serviceType {
  color: var(--dl-color-custom-neutral-light);
}
.formSubmitButton {
  color: var(--dl-color-custom-neutral-light);
  background-color: rgb(0, 119, 229);
  border-radius: var(--dl-radius-radius-radius4);
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  border: 0px;
  margin-top: 2em;
  cursor: pointer;
}
.requestButton {
  color: var(--dl-color-custom-neutral-light);
  margin-top: 1em;
  background-color: var(--dl-color-custom-primary1);
  border-radius: var(--dl-radius-radius-radius4);
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
}
@media(max-width: 991px) {
}
@media(max-width: 767px) {
  .notification-span {
    font-size: var(--dl-size-size-xsmall);
    color: var(--dl-color-custom-neutral-light);
    text-align: center;
  }
  
}
@media(max-width: 479px) {
  form {
    max-width: 90vw;
  }
}