.tax-services-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tax-services-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.tax-services-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-hero-heading {
  max-width: 800px;
  text-align: center;
}
.tax-services-pricing {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-services-pricing1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-services-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tax-services-container03 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-pricing-heading {
  text-transform: none;
  text-decoration: none;
}
.tax-services-container04 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tax-services-pricing-card {
  width: 100%;
}
.tax-services-container05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text {
  text-transform: uppercase;
}
.tax-services-container06 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text001 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price {
  font-size: 4rem;
}
.tax-services-feature-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text002 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text003 {
  line-height: 150%;
}
.tax-services-sub-feature01 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text004 {
  line-height: 150%;
}
.tax-services-sub-feature02 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text005 {
  line-height: 150%;
}
.tax-services-sub-feature03 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text006 {
  line-height: 150%;
}
.tax-services-link {
  width: 100%;
  font-family: "Raleway";
  text-decoration: none;
}
.tax-services-pricing-card1 {
  width: 100%;
}
.tax-services-container07 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text009 {
  text-transform: uppercase;
}
.tax-services-container08 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text010 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price1 {
  font-size: 4rem;
}
.tax-services-feature-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text011 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature04 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text012 {
  line-height: 150%;
}
.tax-services-sub-feature05 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text013 {
  line-height: 150%;
}
.tax-services-sub-feature06 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text014 {
  line-height: 150%;
}
.tax-services-sub-feature07 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text018 {
  line-height: 150%;
}
.tax-services-sub-feature08 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text022 {
  line-height: 150%;
}
.tax-services-link1 {
  width: 100%;
  font-family: "Raleway";
  text-decoration: none;
}
.tax-services-container09 {
  width: 100%;
  display: flex;
  margin-top: 2%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tax-services-container10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-pricing-heading1 {
  text-transform: none;
  text-decoration: none;
}
.tax-services-container11 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tax-services-pricing-card2 {
  width: 100%;
}
.tax-services-container12 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text026 {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.tax-services-container13 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text027 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price2 {
  font-size: 4rem;
}
.tax-services-feature-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text028 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature09 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text029 {
  line-height: 150%;
}
.tax-services-sub-feature10 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text030 {
  line-height: 150%;
}
.tax-services-text031 {
  line-height: 150%;
}
.tax-services-sub-feature11 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text035 {
  line-height: 150%;
}
.tax-services-sub-feature12 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text039 {
  line-height: 150%;
}
.tax-services-sub-feature13 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text042 {
  line-height: 150%;
}
.tax-services-sub-feature14 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text045 {
  line-height: 150%;
}
.tax-services-sub-feature15 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text048 {
  line-height: 150%;
}
.tax-services-button {
  width: 100%;
  display: none;
}
.tax-services-pricing-card3 {
  width: 100%;
}
.tax-services-container14 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text049 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.tax-services-container15 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text050 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price3 {
  font-size: 4rem;
}
.tax-services-feature-container3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text051 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature16 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text052 {
  line-height: 150%;
}
.tax-services-sub-feature17 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text053 {
  line-height: 150%;
}
.tax-services-sub-feature18 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text057 {
  line-height: 150%;
}
.tax-services-sub-feature19 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text061 {
  line-height: 150%;
}
.tax-services-sub-feature20 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text064 {
  line-height: 150%;
}
.tax-services-sub-feature21 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text067 {
  line-height: 150%;
}
.tax-services-sub-feature22 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text070 {
  line-height: 150%;
}
.tax-services-sub-feature23 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text073 {
  line-height: 150%;
}
.tax-services-button1 {
  width: 100%;
  display: none;
}
.tax-services-pricing-card4 {
  width: 100%;
}
.tax-services-container16 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text076 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.tax-services-container17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text077 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price4 {
  font-size: 4rem;
}
.tax-services-feature-container4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text078 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature24 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text079 {
  line-height: 150%;
}
.tax-services-sub-feature25 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text080 {
  line-height: 150%;
}
.tax-services-sub-feature26 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text081 {
  line-height: 150%;
}
.tax-services-sub-feature27 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text085 {
  line-height: 150%;
}
.tax-services-sub-feature28 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text088 {
  line-height: 150%;
}
.tax-services-sub-feature29 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text091 {
  line-height: 150%;
}
.tax-services-sub-feature30 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text094 {
  line-height: 150%;
}
.tax-services-sub-feature31 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text097 {
  line-height: 150%;
}
.tax-services-button2 {
  width: 100%;
  display: none;
}
.tax-services-pricing-card5 {
  width: 100%;
}
.tax-services-container18 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text100 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.tax-services-container19 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text101 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price5 {
  font-size: 4rem;
}
.tax-services-feature-container5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text102 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature32 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text103 {
  line-height: 150%;
}
.tax-services-sub-feature33 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text104 {
  line-height: 150%;
}
.tax-services-sub-feature34 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text105 {
  line-height: 150%;
}
.tax-services-sub-feature35 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text109 {
  line-height: 150%;
}
.tax-services-sub-feature36 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text112 {
  line-height: 150%;
}
.tax-services-sub-feature37 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text115 {
  line-height: 150%;
}
.tax-services-sub-feature38 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text118 {
  line-height: 150%;
}
.tax-services-sub-feature39 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text121 {
  line-height: 150%;
}
.tax-services-button3 {
  width: 100%;
  display: none;
}
.tax-services-container20 {
  width: 100%;
  display: flex;
  margin-top: 2%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tax-services-container21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-pricing-heading2 {
  text-transform: none;
  text-decoration: none;
}
.tax-services-container22 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tax-services-pricing-card6 {
  width: 100%;
}
.tax-services-container23 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text124 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.tax-services-container24 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-free-plan-price6 {
  font-size: 3rem;
}
.tax-services-feature-container6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text125 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature40 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text126 {
  line-height: 150%;
}
.tax-services-sub-feature41 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text127 {
  line-height: 150%;
}
.tax-services-text128 {
  line-height: 150%;
}
.tax-services-sub-feature42 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text132 {
  line-height: 150%;
}
.tax-services-sub-feature43 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text136 {
  line-height: 150%;
}
.tax-services-sub-feature44 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text139 {
  line-height: 150%;
}
.tax-services-sub-feature45 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text142 {
  line-height: 150%;
}
.tax-services-sub-feature46 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text145 {
  line-height: 150%;
}
.tax-services-link2 {
  width: 100%;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 500;
  text-decoration: none;
}
.tax-services-download-center {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-services-download {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.tax-services-container25 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text147 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.tax-services-container26 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tax-services-link3 {
  text-decoration: none;
}
@media(max-width: 991px) {
  .tax-services-hero1 {
    flex-direction: column;
  }
  .tax-services-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .tax-services-hero-heading {
    text-align: center;
  }
  .tax-services-container04 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-pricing-card1 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-container11 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card2 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-pricing-card3 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-pricing-card4 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-pricing-card5 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-container22 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card6 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
}
@media(max-width: 767px) {
  .tax-services-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .tax-services-container04 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-pricing-card1 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-container11 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card2 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-pricing-card3 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-pricing-card4 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-pricing-card5 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-container22 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card6 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-download {
    flex-direction: column;
  }
  .tax-services-container25 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .tax-services-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
