.whatsapp-link{
  color: rgb(255, 255, 255);
  align-self: flex-start;
  font-size: 2em;
}
.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.contact-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-hero-heading {
  max-width: 800px;
  text-align: center;
}
.contact-container02 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
  background-color: var(--dl-color-custom-accent1);
}
.contact-form {
  width: 100%;
  height: 100%;
  display: none;
  position: relative;
  align-self: flex-start;
}
.contact-container03 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
}
.contact-container04 {
  width: 50%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.contact-textinput {
  height: var(--dl-size-size-small);
}
.contact-textinput1 {
  height: var(--dl-size-size-small);
}
.contact-textinput2 {
  height: var(--dl-size-size-small);
}
.contact-container05 {
  width: 50%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.contact-container06 {
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-container07 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.contact-container08 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-container08 div {
  margin-bottom: 2%;
}
.contact-email {
  color: rgb(255, 255, 255);
  align-self: flex-start;
  font-size: 2em;
}
.contact-text {
  color: rgb(255, 255, 255);
  align-self: flex-start;
  font-size: 2em;
}
.contact-container09 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact-tel {
  color: rgb(255, 255, 255);
  align-self: flex-start;
}
.contact-text1 {
  color: rgb(255, 255, 255);
  align-self: flex-start;
}
@media(max-width: 991px) {
  .contact-hero1 {
    flex-direction: column;
  }
  .contact-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .contact-hero-heading {
    text-align: center;
  }
  .contact-textinput {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-textinput1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-textinput2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-textarea {
    width: 100%;
    height: 100%;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-container06 {
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 767px) {
  .contact-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .contact-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .contact-container07 {
    flex-direction: column;
  }
  .contact-container08 {
    margin-bottom: 5%;
  }
  .contact-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
