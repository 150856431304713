.accounting-services-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.accounting-services-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.accounting-services-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.accounting-services-hero-heading {
  max-width: 800px;
  text-align: center;
}
.accounting-services-pricing {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accounting-services-pricing1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accounting-services-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.accounting-services-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.accounting-services-text {
  text-transform: uppercase;
}
.accounting-services-container4 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.accounting-services-text004 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.accounting-services-free-plan-price {
  font-size: 4rem;
}
.accounting-services-text005 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.accounting-services-feature-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.accounting-services-feature {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text006 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text007 {
  line-height: 150%;
}
.accounting-services-sub-feature01 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text008 {
  line-height: 150%;
}
.accounting-services-sub-feature02 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text009 {
  line-height: 150%;
}
.accounting-services-text010 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature01 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text011 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature03 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text012 {
  line-height: 150%;
}
.accounting-services-sub-feature04 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text013 {
  line-height: 150%;
}
.accounting-services-sub-feature05 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text017 {
  line-height: 150%;
}
.accounting-services-sub-feature06 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text021 {
  line-height: 150%;
}
.accounting-services-text025 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature02 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text028 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature07 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text029 {
  line-height: 150%;
}
.accounting-services-text030 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature03 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text031 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature08 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text032 {
  line-height: 150%;
}
.accounting-services-sub-feature09 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text033 {
  line-height: 150%;
}
.accounting-services-text034 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature04 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text035 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature10 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text036 {
  line-height: 150%;
}
.accounting-services-text037 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature05 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text038 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature11 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text039 {
  line-height: 150%;
}
.accounting-services-sub-feature12 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text040 {
  line-height: 150%;
}
.accounting-services-sub-feature13 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text041 {
  line-height: 150%;
}
.accounting-services-text046 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-link {
  width: 100%;
  font-family: "Raleway";
  text-decoration: none;
}
.accounting-services-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.accounting-services-text047 {
  text-transform: uppercase;
}
.accounting-services-container6 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.accounting-services-text051 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.accounting-services-free-plan-price1 {
  font-size: 4rem;
}
.accounting-services-text052 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.accounting-services-feature-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.accounting-services-feature06 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text053 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature14 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text054 {
  line-height: 150%;
}
.accounting-services-sub-feature15 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text055 {
  line-height: 150%;
}
.accounting-services-sub-feature16 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text056 {
  line-height: 150%;
}
.accounting-services-text057 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature07 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text058 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature17 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text059 {
  line-height: 150%;
}
.accounting-services-sub-feature18 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text060 {
  line-height: 150%;
}
.accounting-services-sub-feature19 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text064 {
  line-height: 150%;
}
.accounting-services-sub-feature20 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text068 {
  line-height: 150%;
}
.accounting-services-text072 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature08 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text075 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature21 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text076 {
  line-height: 150%;
}
.accounting-services-text077 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature09 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text078 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature22 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text079 {
  line-height: 150%;
}
.accounting-services-sub-feature23 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text080 {
  line-height: 150%;
}
.accounting-services-text081 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text082 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature24 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text083 {
  line-height: 150%;
}
.accounting-services-text084 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature11 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text085 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature25 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text086 {
  line-height: 150%;
}
.accounting-services-text087 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-feature12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text088 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature26 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text089 {
  line-height: 150%;
}
.accounting-services-sub-feature27 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text090 {
  line-height: 150%;
}
.accounting-services-sub-feature28 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text091 {
  line-height: 150%;
}
.accounting-services-sub-feature29 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text092 {
  line-height: 150%;
}
.accounting-services-sub-feature30 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text093 {
  line-height: 150%;
}
.accounting-services-sub-feature31 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text094 {
  line-height: 150%;
}
.accounting-services-text095 {
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
}
.accounting-services-link1 {
  width: 100%;
  font-family: "Raleway";
  text-decoration: none;
}
.accounting-services-container7 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.accounting-services-text096 {
  text-transform: uppercase;
}
.accounting-services-container8 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.accounting-services-free-plan-price2 {
  font-size: 4rem;
  font-style: italic;
  font-weight: 600;
}
.accounting-services-text100 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.accounting-services-feature-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.accounting-services-feature13 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text101 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature32 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text102 {
  line-height: 150%;
}
.accounting-services-sub-feature33 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text103 {
  line-height: 150%;
}
.accounting-services-sub-feature34 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text104 {
  line-height: 150%;
}
.accounting-services-feature14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text105 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature35 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text106 {
  line-height: 150%;
}
.accounting-services-sub-feature36 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text107 {
  line-height: 150%;
}
.accounting-services-sub-feature37 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text111 {
  line-height: 150%;
}
.accounting-services-sub-feature38 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text115 {
  line-height: 150%;
}
.accounting-services-feature15 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text119 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature39 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text120 {
  line-height: 150%;
}
.accounting-services-feature16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text121 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature40 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text122 {
  line-height: 150%;
}
.accounting-services-sub-feature41 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text123 {
  line-height: 150%;
}
.accounting-services-sub-feature42 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text124 {
  line-height: 150%;
}
.accounting-services-feature17 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text125 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature43 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text126 {
  line-height: 150%;
}
.accounting-services-sub-feature44 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text127 {
  line-height: 150%;
}
.accounting-services-sub-feature45 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text128 {
  line-height: 150%;
}
.accounting-services-sub-feature46 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text129 {
  line-height: 150%;
}
.accounting-services-sub-feature47 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text130 {
  line-height: 150%;
}
.accounting-services-feature18 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  padding-bottom: 5%;
}
.accounting-services-text131 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.accounting-services-sub-feature48 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text132 {
  line-height: 150%;
}
.accounting-services-sub-feature49 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text133 {
  line-height: 150%;
}
.accounting-services-sub-feature50 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text134 {
  line-height: 150%;
}
.accounting-services-sub-feature51 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text135 {
  line-height: 150%;
}
.accounting-services-sub-feature52 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.accounting-services-text136 {
  line-height: 150%;
}
.accounting-services-link2 {
  width: 100%;
  font-family: "Raleway";
  text-decoration: none;
}
@media(max-width: 991px) {
  .accounting-services-hero1 {
    flex-direction: column;
  }
  .accounting-services-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .accounting-services-hero-heading {
    text-align: center;
  }
  .accounting-services-container2 {
    align-items: center;
    flex-direction: column;
  }
  .accounting-services-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .accounting-services-pricing-card1 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .accounting-services-pricing-card2 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
}
@media(max-width: 767px) {
  .accounting-services-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .accounting-services-container2 {
    align-items: center;
    flex-direction: column;
  }
  .accounting-services-pricing-card {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .accounting-services-pricing-card1 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .accounting-services-pricing-card2 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .accounting-services-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .accounting-services-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
