.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(4, 21, 37, 0.9);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0; 
  left: 0; 
  z-index: 1;
}
.layover {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(4, 21, 37, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.layover-button {
  color: var(--dl-color-custom-neutral-light);
  margin-top: 1em;
  background-color: var(--dl-color-custom-primary1);
  border-radius: var(--dl-radius-radius-radius4);
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;

}
.notification-span {
  font-size: var(--dl-size-size-small);
  color: var(--dl-color-custom-neutral-light);
  text-align: center;
}
.form-close {
  position: absolute;
  top: 15%;
  right: 15%;
  color: var(--dl-color-custom-neutral-light);
  padding-top: var(--dl-space-space-halfunit);
  border: 2px solid;
  border-color: var(--dl-color-custom-neutral-light);
  border-radius: var(--dl-radius-radius-radius4);
  padding: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
}
.notification-close {
  position: absolute;
  top: 15%;
  right: 15%;
  color: var(--dl-color-custom-neutral-light);
  padding-top: var(--dl-space-space-halfunit);
  border: 2px solid;
  border-color: var(--dl-color-custom-neutral-light);
  border-radius: var(--dl-radius-radius-radius4);
  padding: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
}
.request-input {
  display: flex;
  flex-direction: column;
  min-width: 333px;
}
.request-input select {
  border-radius: var(--dl-radius-radius-radius4);
  height: var(--dl-space-space-twounits);
  border: 0px;
}
.request-input input {
  border-radius: var(--dl-radius-radius-radius4);
  border: 0px;
  height: var(--dl-space-space-twounits);
}
.request-input input, .request-input label {
  margin-top: 1em;
}
.request-file-upload {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.request-file-upload input {
  padding: 0%;
  color: var(--dl-color-custom-neutral-light);
  height: var(--dl-space-space-twounits);
}
.request-input button {
  border: var(--dl-color-gray-white);
}
.request-file-upload input::file-selector-button:hover {
  cursor: pointer;
}
.request-file-upload input::file-selector-button {
  color: var(--dl-color-custom-neutral-light);
  background-color: rgb(0, 119, 229);
  border-radius: var(--dl-radius-radius-radius4);
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  border: 0px;
}
label {
  color: var(--dl-color-custom-neutral-light);
  font-size: small;
}
.request-span {
  font-size: small;
  color: var(--dl-color-custom-neutral-light);
}
@media(max-width: 991px) {
}
@media(max-width: 767px) {
  .notification-span {
    font-size: var(--dl-size-size-xsmall);
    color: var(--dl-color-custom-neutral-light);
    text-align: center;
  }
  
}
@media(max-width: 479px) {
  form {
    max-width: 90vw;
  }

}
