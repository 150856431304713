.header-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.header-logo {
  text-decoration: none;
}
.header-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.header-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.header-nav12 {
  transition: 0.3s;
  margin-left: 0px;
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  padding-left: 2%;
  padding-right: 2%;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.active {
  color: var(--dl-color-primary-300);
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
}
.header-nav22 {
  margin-left: 0px;
  border-color: var(--dl-color-gray-white);
  padding-left: 2%;
  padding-right: 2%;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.header-nav32 {
  margin-left: 0px;
  border-color: var(--dl-color-gray-white);
  padding-left: 2%;
  padding-right: 2%;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.header-nav42 {
  margin-left: 0px;
  border-color: var(--dl-color-gray-white);
  padding-left: 2%;
  padding-right: 2%;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.header-nav52 {
  margin-left: 0px;
  padding-left: 2%;
  padding-right: 2%;
  text-decoration: none;
}
.header-buttons {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-login {
  margin-right: 32px;
}
.header-burger-menu {
  display: none;
}
.header-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(20, 26, 34);
}
/* nav menu open */
.header-mobile-menu1.open {
  transform: translateX(0%);
}
.header-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.header-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.header-nav121 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-nav121:hover {
  cursor: pointer;
}
.header-nav221 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-nav221:hover {
  cursor: pointer;
}
.header-nav321 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-nav321:hover {
  cursor: pointer;
}
.header-nav421 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-nav421:hover {
  cursor: pointer;
}
.header-nav521 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-nav521:hover {
  cursor: pointer;
}
.header-buttons1 {
  display: none;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}




@media(max-width: 767px) {
  .header-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .header-nav121 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-nav221 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-nav321 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-nav421 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-nav521 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .header-navbar-interactive {
    height: 99px;
    padding: var(--dl-space-space-unit);
    z-index: auto;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    margin-bottom: 0px;
    padding-right: 16px;
  }
  .header-desktop-menu {
    display: none;
  }
  .header-burger-menu {
    display: flex;
    flex-direction: column;
  }
  .header-icon {
    z-index: inherit;
  }
  .header-mobile-menu1 {
    padding: 16px;
  }
  .header-buttons1 {
    display: none;
  }
  .header-root-class-name {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
  }
}
